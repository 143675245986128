import React from "react";
import { useInView } from "react-intersection-observer";
import "./components/css/Navbar.scss";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Keynote from "./components/Keynote";
import Navbar from "./components/Navbar";
import People from "./components/People";
import Program from "./components/Program";
import Solver from "./components/Solver";
import Talks from "./components/Talks";

function App() {
  const [overview, inView] = useInView({
    threshold: 0.1,
  });

  const [program, inView2] = useInView({
    threshold: 0.1,
  });

  const [keynote, inView3] = useInView({
    threshold: 0.1,
  });

  const [talks, inView4] = useInView({
    threshold: 0.1,
  });

  const [people, inView5] = useInView({
    threshold: 0.1,
  });

  const [solvers, inView6] = useInView({
    threshold: 0.1,
  });

  // const [papers, inView7] = useInView({
  //   threshold: 0.1,
  // });

  const activeTab = () => {
    if (inView) {
      return 1;
    } else if (inView2 && !inView) {
      return 2;
    } else if (inView3 && !inView && !inView2) {
      return 3;
    } else if (inView4 && !inView && !inView2 && !inView3) {
      return 4;
    } else if (inView5 && !inView && !inView2 && !inView3 && !inView4) {
      return 5;
    } else if (
      inView6 &&
      !inView &&
      !inView2 &&
      !inView3 &&
      !inView4 &&
      !inView5
    ) {
      return 6;
    }
  };

  const executeScroll = (selection) => {
    switch (selection) {
      case "program":
        return document
          .getElementById("program")
          .scrollIntoView({ behavior: "smooth", block: "start" });
      case "keynote":
        return document
          .getElementById("keynote")
          .scrollIntoView({ behavior: "smooth", block: "start" });
      case "talks":
        return document
          .getElementById("talks")
          .scrollIntoView({ behavior: "smooth", block: "start" });
      case "people":
        return document
          .getElementById("people")
          .scrollIntoView({ behavior: "smooth", block: "start" });
      case "solvers":
        return document
          .getElementById("solvers")
          .scrollIntoView({ behavior: "smooth", block: "start" });
      // case "papers":
      //   return document
      //     .getElementById("papers")
      //     .scrollIntoView({ behavior: "smooth", block: "start" });
      default:
        window.scrollTo({ top: 0, behavior: "smooth", block: "start" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="App">
        <Navbar executeScroll={executeScroll} activeTab={activeTab} />

        <div ref={overview} id="overview">
          <Home />
        </div>

        <div ref={program} id="program" className="newSection">
          <Program executeScroll={executeScroll} />
        </div>

        <div ref={keynote} id="keynote" className="newSection">
          <Keynote />
        </div>

        <div ref={talks} id="talks" className="newSection">
          <Talks />
        </div>

        <div ref={people} id="people" className="newSection">
          <People />
        </div>

        <div ref={solvers} id="solvers" className="newSection">
          <Solver />
        </div>

        {/* <div ref={papers} id="papers" className="newSection">
          <Papers />
        </div> */}

        {activeTab() !== 1 && (
          <div className="scroll_up" onClick={scrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </>
  );
}

export default App;
