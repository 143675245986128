import React from "react";
import "./css/Solver.scss";

function Solver() {
  return (
    <div className="text">
      <h2>Solvers</h2>
      <h3>
        TrajectoryOptimization.jl
        <a
          href="https://github.com/RoboticExplorationLab/TrajectoryOptimization.jl"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-github-square"></i>
        </a>
      </h3>
      <p>
        The Robotic Exploration Lab at Carnegie Mellon University has developed
        a suite of tools in the Julia programming language for setting up and
        solving trajectory optimization problems. Their core solver, ALTRO, uses
        iterative LQR (iLQR) with an augmented Lagrangian method (ALM) for
        handling general path constraints, coupled with an active-set method for
        solution polishing. Despite being written in a high-level programming
        language, ALTRO demonstrates impressive performance on convex programs,
        second-order cone programs, and general nonlinear trajectory
        optimization problems. It natively handles 3D rotations, performing the
        optimization on the Lie algebra and using unit quaternions as the global
        parameterization.
        <br />
        <a
          href="https://github.com/RoboticExplorationLab/AltroTutorials.jl"
          target="_blank"
          rel="noreferrer"
        >
          Link to Tutorial
        </a>
      </p>
      <h3>
        OCS2
        <a
          href="https://github.com/leggedrobotics/ocs2"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-github-square"></i>
        </a>
      </h3>
      <p>
        OCS2 is a C++ toolbox tailored for Optimal Control of Switched Systems
        (OCS2). The toolbox provides an efficient implementation of the
        Differential Dynamic Programming (DDP) algorithm in continuous-time
        (known as SLQ) and discrete-time (known as iLQR) domains. OCS2 handles
        general path constraints through augmented Lagrangian and relaxed
        barrier methods. To facilitate the application of OCS2 in robotic tasks,
        it provides the user with additional tools to set up the system dynamics
        (such as kinematic or dynamic models) and cost/constraints (such as
        self-collision avoidance and end-effector tracking) from a URDF model.
        The library also provides an automatic differentiation tool to calculate
        derivatives of the system dynamics, constraints, and cost. The toolbox’s
        efficient and numerically stable implementations in conjunction with its
        user-friendly interface have paved the way for deploying it in an MPC
        fashion for numerous robotic applications with limited onboard
        computation power.
      </p>
      <h3>
        Crocoddyl
        <a
          href="https://github.com/loco-3d/crocoddyl"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-github-square"></i>
        </a>
      </h3>
      <p>
        Crocoddyl is an optimal control solver targeting whole-body model
        predictive control on the manipulator and legged robots. It is built
        from the beginning on the separation between the front-end (graph of
        cost and constraints, integration, evaluation of the function and their
        derivatives) and the back-end (numerical constrained solver). While
        offering a generic interface, the front end is mostly built upon the
        rigid body dynamic library Pinocchio, which empowers Crocoddyl with a
        very efficient evaluation of the costs, dynamics, and their derivatives
        and offering the possibility to code-generate the whole computational
        pipelines. For legged robots, contact constraints are directly handled
        inside the robot dynamic and solved with an original algorithm, enabling
        robust and efficient evaluation. The evaluation graph is built following
        the "discretize first, solve second" approach, enabling a complete
        decoupling of the back-end and the front-end. Several back-end solvers
        are proposed, mostly implementing variations of the DDP algorithm. The
        main DDP solver implements a multiple shooting strategy and box
        constraints. We are releasing an equality/inequality-constraint
        augmented Lagrangian solver and a solver able to consider external
        parameters (such as contact location, timings, or codesign parameters).
        Crocoddyl has been used for implementing online whole-body model
        predictive control with low-level torque servo on humanoid, manipulator,
        and quadruped robots. Guidelines for reproducing the controllers will be
        proposed. Crocoddyl is also a living international collaboration based
        on open sourcing and BSD-3 licensing. It features lead developments by
        LAAS-CNRS, Inria Paris, University of Edinburgh, University of Oxford,
        University of Trento, Max Planck Institute, or NY University. We are
        happy to guide new users, and contributions are welcome.
      </p>
      <h3>
        SCP Toolbox
        <a
          href="https://github.com/dmalyuta/scp_traj_opt"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-github-square"></i>
        </a>
      </h3>
      <p>
        The SCP Toolbox is a Julia library that provides a parser-solver
        interface for a suite of real-time capable Sequential Convex Programming
        (SCP) algorithms. A high-level optimal control problem parser provides
        users with the ability to solve generic nonconvex trajectory problems
        using the following algorithms: LCvx, SCvx, PTR, and GuSTO. These
        algorithms have been applied on aerospace and robotic independent
        research problems that may be relevant for organizations such as NASA,
        SpaceX, Blue Origin, and Masten Space Systems. The toolbox originates
        from a recent{" "}
        <a
          href="https://arxiv.org/abs/2106.09125"
          target="_blank"
          rel="noreferrer"
        >
          comprehensive tutorial paper
        </a>{" "}
        featuring the theory and practice of lossless convexification and SCP
        algorithms. Alongside a{" "}
        <a
          href="https://www.malyuta.name/optimization/tooling/2021/07/15/scp-tutorial.html"
          target="_blank"
          rel="noreferrer"
        >
          beginner tutorial
        </a>{" "}
        the toolbox comes with 8 predefined examples including quadrotor flight,
        NASA Apollo rendezvous, Mars landing, and SpaceX Starship landing flip.
      </p>
      <a
        href="https://www.malyuta.name/optimization/tooling/2021/07/15/scp-tutorial.html"
        target="_blank"
        rel="noreferrer"
      >
        Link to Tutorial
      </a>
    </div>
  );
}

export default Solver;
