import React from "react";
import "./css/People.scss";
import { organizers } from "../assets/data";

function People() {
  return (
    <div className="text">
      <h2 className="people_header">Organizers</h2>
      {organizers.map((organizer, index) => {
        return (
          <div className="profile" key={index}>
            <div className="profile_description">
              <img
                className="profile_image"
                alt="ProfileImage"
                src={organizer.image}
              />
              <h4>{organizer.name}</h4>
              <p>{organizer.description}</p>
            </div>
          </div>
        );
      })}

      {/* <li className="speakers">
          <a
            href="https://depts.washington.edu/uwacl/"
            target="_blank"
            rel="noreferrer"
          >
            Beh&ccedil;et A&ccedil;ikmes&#808;e
            <i class="fas fa-external-link-alt"></i>
          </a>
        </li>
        <li className="speakers">
          <a
            href="https://agile.seas.harvard.edu/"
            target="_blank"
            rel="noreferrer"
          >
            Scott Kuindersma
            <i class="fas fa-external-link-alt"></i>
          </a>
        </li>
        <li className="speakers">
          <a
            href="https://biomimetics.mit.edu/"
            target="_blank"
            rel="noreferrer"
          >
            Sangbae Kim
            <i class="fas fa-external-link-alt"></i>
          </a>
        </li>
        <li className="speakers">
          <a
            href="https://uwaterloo.ca/robohub/people-profiles/katja-mombaur"
            target="_blank"
            rel="noreferrer"
          >
            Katja Mombaur
            <i class="fas fa-external-link-alt"></i>
          </a>
        </li>
        <li className="speakers">
          <a href="https://rsl.ethz.ch/" target="_blank" rel="noreferrer">
            Marco Hutter
            <i class="fas fa-external-link-alt"></i>
          </a>
        </li>
        <li className="speakers">
          <a
            href="https://www.andrew.cmu.edu/user/amj1/"
            target="_blank"
            rel="noreferrer"
          >
            Aaron Johnson
            <i class="fas fa-external-link-alt"></i>
          </a>
        </li>
        <li className="speakers">
          <a
            href="https://cs-people.bu.edu/donhk/"
            target="_blank"
            rel="noreferrer"
          >
            Donghuun Kim
            <i class="fas fa-external-link-alt"></i>
          </a>
        </li> */}
    </div>
  );
}

export default People;
