import React from "react";
import "../App.scss";
import "./css/Home.scss";

function Home() {
  return (
    <>
      <div>
        <div className="home_image">
          <div className="overlay_text">
            <h1>
              Software Tools for <br /> Real-Time Optimal Control
              {/* <br/>
              <div>
              <a className="quick_link" href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09" target="_blank" rel="noreferrer">Join Now</a>
              <br/>
              <a className="quick_link" href="https://www.wonder.me/r?id=49a37bb0-aa46-4677-8451-959caaa8c782" target="_blank" rel="noreferrer">Wonder.Me</a>
              </div> */}
            </h1>
          </div>
        </div>
        <div className="text-container">
          <div className="text">
            <h2>Abstract</h2>
            <p>
              Many modern control methods, such as model-predictive control,
              rely heavily on solving optimization problems in real time. In
              particular, the ability to efficiently solve optimal control
              problems has enabled many recent breakthroughs in achieving highly
              dynamic behaviors for complex robotic systems. The ability to
              solve these optimization problems with real-time performance is
              extremely challenging, and currently a very active area of
              research across a wide range of disciplines, including robotics,
              aerospace, operations research, finance, etc. In an effort to make
              these powerful methods more accessible to practitioners who may
              not have the requisite background in nonlinear optimization,
              dynamics, or control, the robotics community has generated several
              open-source software packages dedicated to setting up and solving
              optimal control problems. This tutorial-style workshop, targeted
              to a broad audience, aims to both inform the community about the
              merits of these packages, as well as highlight recent successes
              achieved by modern optimal control techniques.
            </p>
          </div>
          <div className="text">
            <h2>Overview</h2>
            <p>
              The workshop will be focused around in-depth tutorials for three
              open-source packages (link to the Solvers section on the website)
              developed by the robotics community for solving optimal control
              problems. During the workshop, participants will be given the
              opportunity to set up and solve simple trajectory optimization
              problems using the various packages. Participants will also be
              given the opportunity to join break-out rooms with experts or
              package maintainers in order to ask questions about leveraging
              these packages for particular applications. These in-depth dives
              will be accompanied by several lightning-talk sessions focused on
              compelling applications of optimal control along with recent
              algorithmic breakthroughs. Prior to the workshop, we will be
              hosting a short seminar series for the keynote speakers, who will
              attend the workshop for live Q&A sessions.
            </p>
            <p>
              This workshop is geared towards all experience levels, with an
              intentional focus on those who are interested in optimal control
              but may not be intimately familiar with the underlying mathematics
              and algorithms used to set up and solve these challenging
              problems. Our goal is to highlight recent tooling developed by the
              robotics community that aims to reduce the barrier to entry and
              enable broader adoption of these promising techniques.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
