export const organizers = [
    {
      name: "Brian Jackson",
      image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/v1615259862/brian_cmt1bn.png",
      description: "Brian is a PhD student in the Robotics Institute at Carnegie Mellon University with a Master's degree from Stanford University, working in the Robotic Exploration Lab with Dr. Zachary Manchester. A recipient of the NSF Fellowship, he is the primary developer of RobotDynamics.jl, TrajectoryOptimization.jl, and ALTRO.jl, which sets state-of-the-art performance for trajectory optimization in Julia, a high-level easy-to-use programming language. His research focuses on developing algorithms for doing real-time trajectory optimization, including applications in space exploration and aerospace.",
    },
    {
      name: "Farbod Farshidian",
      image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/v1624933991/Farbod_Headshot_fjbjqv.png",
      description: "Farbod Farshidian is a senior scientist at Robotic System Lab, ETH Z\"urich. In his research, he focuses on the motion planning and control of mobile robots, intending to develop algorithms and techniques to endow these robotic platforms to operate autonomously in real-world applications. His expertise covers optimization-based control, motion planning of legged systems, and mobile manipulators."
    },
    {
      name: "Zac Manchester",
      image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/v1615259875/zac_grmfx3.jpg",
      description: "Zac Manchester is an Assistant Professor in The Robotics Institute at Carnegie Mellon and leads the Robotic Exploration Lab. His research spans control, motion planning, and optimization, with applications to robotic space exploration, legged locomotion, and autonomous driving. Previously, he was an Assistant Professor of Aeronautics and Astronautics at Stanford University and a Postdoctoral Fellow in the Agile Robotics Lab at Harvard. Zac holds a Ph.D. in aerospace engineering from Cornell University, is a member of the Breakthrough Starshot Advisory Committee, and was a recipient of the 2018 NASA Early Career Faculty Award and a 2020 Google Faculty Award."
    }
];

export const speakers = [
  {
    name: "Marco Hutter - ETH Zurich",
    title: "Real-time optimal control for legged locomotion & manipulation",
    externalLink: "https://rsl.ethz.ch/",
    image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/v1624737913/MPC%20Keynote%20Speakers/hutter_image_xn7po4.jpg",
    date: "Monday June 28 @ 10:00 PDT / 13:00 EDT / 19:00 CET",
    description: "Marco is an assistant professor for robotic systems at ETH Zurich and co-founder of ANYbotics AG, a Zurich-based company developing legged robots for industrial applications. Marco’s research interests are in the development of novel machines and actuation concepts together with the underlying control, planning, and learning algorithms for locomotion and manipulation. His works find application from electrically actuated quadrupeds like ANYmal to large-scale autonomous excavators used for digital fabrication and disaster mitigation. Marco is part of the National Centre of Competence in Research (NCCR) Robotics and NCCR Digital Fabrication and PI in various international projects (e.g. EU Thing, NI) and challenges (e.g. DARPA SubT).",
    label: "Watch Video",
    video: "https://www.youtube.com/watch?v=sjAENmtO4bA&list=PLZnJoM76RM6IlMmzEp20qkeXZUSVYMOAv&index=1"
  },
  {
    name: "Donghyun Kim - UMass",
    title: "Robot Software for Optimal Control",
    externalLink: "https://groups.cs.umass.edu/daros/",
    image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1624737915/MPC%20Keynote%20Speakers/donghyun_image_qug03r.jpg",
    date: "Wednesday, June 30 @ 10:00 PDT / 13:00 EDT / 19:00 CET",
    description: "Donghyun Kim is an Assistant Professor of College of Information and Computer Sciences at the University of Massachusetts Amherst. His primary research area is the dynamic locomotion of legged systems with a focus on the development of a hierarchical control framework and its experimental validation. During his Ph.D. at the University of Texas at Austin, Donghyun developed frameworks including joint-level feedback control, whole-body control, footstep planner, and its uncertainty analysis for passive-ankle biped robots. During his postdoctoral research at MIT, he demonstrated Mini-Cheetah running up to 3.7 m/s with his new integrated control framework including a new whole-body controller and model-predictive control. Currently, he is extending his research area to a perception-based high-level decision algorithm to push forward the robots' athletic intelligence.",
    label: "Watch Video",
    video: "https://youtu.be/QlUEQpq6jK0"
  },
  {
    name: "Aaron Johnson - CMU",
    title: "Trajectory Optimization with Changing Contact Conditions",
    externalLink: "https://www.andrew.cmu.edu/user/amj1/",
    image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1624737918/MPC%20Keynote%20Speakers/Johnson_image_e6mynp.jpg",
    date: "Friday, July 2 @ 10:00 PDT / 13:00 EDT / 19:00 CET",
    description: "Aaron M. Johnson received the B.S. degree in electrical and computer engineering from Carnegie Mellon University in 2008 and the Ph.D. degree in electrical and systems engineering from the University of Pennsylvania in 2014. He is currently an Assistant Professor of Mechanical Engineering at Carnegie Mellon University, with appointments in the Robotics Institute and Electrical and Computer Engineering Department. He was previously a Postdoctoral Fellow at Carnegie Mellon University and the University of Pennsylvania. His research interests include legged locomotion, hybrid dynamical systems, robust control, and bioinspired robotics. Dr. Johnson received the NSF CAREER award in 2020 and the ARO Young Investigator Award in 2018.",
    label: "Watch Video",
    video: "https://www.youtube.com/watch?v=zkgB2aVYraQ&list=PLZnJoM76RM6IlMmzEp20qkeXZUSVYMOAv&index=3"
  },
  {
    name: "Scott Kuindersma - Boston Dynamics",
    title: "Do You Love MPC? Robot Dancing Using Optimal Control",
    externalLink: "https://agile.seas.harvard.edu/",
    image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1624737911/MPC%20Keynote%20Speakers/kuindersma_image_n7xnqm.jpg",
    date: "Friday, July 9 @ 10:30 PDT / 13:30 EDT / 19:30 CET",
    description: "Scott Kuindersma is a Research Scientist and the Atlas team lead at Boston Dynamics. Previously he was an Assistant Professor of Engineering and Computer Science at Harvard University and a postdoc in the Robot Locomotion Group at MIT. His work is focused on deploying optimization algorithms to produce high-performance behaviors in mobile robots.",
    label: "Watch Video",
    video: "https://www.youtube.com/watch?v=mlTLxpKdHfA"
  }, 
  {
    name: "Behçet Açikmes̨e - University of Washington",
    title: "Real-Time Optimal Control - Aerospace Perspective",
    externalLink: "https://depts.washington.edu/uwacl/",
    image: "https://res.cloudinary.com/dwbqzx4rr/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1624737922/MPC%20Keynote%20Speakers/bechet_image_aqgjof.jpg",
    date: "Monday, July 12 @ 8:00 PDT / 11:00 EDT / 17:00 CET",
    description: "Behçet Açikmes̨e is a professor in the William E. Boeing Department of Aeronautics and Astronautics and an adjunct faculty member in the Department of Electrical Engineering at University of Washington, Seattle. He received his Ph.D. in Aerospace Engineering from Purdue University. He was a senior technologist at JPL and a lecturer at Caltech. At JPL, he developed control algorithms for planetary landing, spacecraft formation flying, and asteroid and comet sample return missions. He developed the “flyaway” control algorithms used successfully in NASA’s Mars Science Laboratory (MSL) and Mars 2020 missions during the landings of Curiosity and Perseverance rovers on Mars. Dr. Açikmes̨e invented a novel real-time convex optimization based planetary landing guidance algorithm (G-FOLD), which is the first demonstration of a real-time optimization algorithm on a reusable rocket. He is a recipient of the NSF CAREER Award, IEEE Technical Excellence in Aerospace Controls, numerous NASA Achievement awards for his contributions to NASA missions and technology development. He is an associate editor of IEEE Control System Magazine and AIAA Journal of Guidance, Control, and Dynamics.",
    label: "Watch Video",
    video: "https://www.youtube.com/watch?v=n8-QzhM0wSw"
  }
]

export const lightningTalks = [ 
  {
    title: "Towards Real-time Planning of Hybrid Manipulation Primitives with Differential Dynamic Programming",
    authors: "Neel Doshi and Alberto Rodriguez",
    abstract: "https://drive.google.com/file/d/1dKMrJqU1LHNfFYIfVm28pHIl1R4YBbTA/view",
    clip: "https://drive.google.com/file/d/1SuOIbpWiMZIMYOQzMKkwdgCLa_RXj_jJ/view"
  }, 
  {
    title: "Heuristic-based Nonlinear MPC with Uncertainty",
    authors: "Marc D. Killpack, Phillip Hyatt, Curtis Johnson, Tyler Quackenbush, Taylor Sorensen, and David Wingate",
    abstract: "https://drive.google.com/file/d/1Rd_nytH5RaUhQV1SQzG9mz-n0j1d_k4j/view",
    clip: "https://drive.google.com/file/d/1aP_e6xWUgNxl5Dxm9UigMalKk0wv1bZW/view"
  },
  {
    title: "Parallelizing POMCP to Solve Complex POMDPs",
    authors: "Semanti Basu, Sreshtaa Rajesh, Kaiyu Zheng, Stefanie Tellex, R. Iris Bahar",
    abstract: "https://drive.google.com/file/d/1wBE3i2lqg9BqC2lUWlx76CuIV3O7skMl/view",
    clip: "https://drive.google.com/file/d/15v3mM_LxfbzV0arqOX7GAbGXs6qzPqGW/view"
  },
  {
    title: "Toward Real-Time Model Predictive Control of Legged Robots Using Structured Prediction",
    authors: "He Li and Patrick M. Wensing",
    abstract: "https://drive.google.com/file/d/1PyiRs1g8nGCmgVJXsVWycAB4BDbSogXx/view",
    clip: "https://drive.google.com/file/d/10O79ZdtjxFf5yXPiR2gpZzwnA3k0W4Hf/view"
  },
  {
    title: "Optimal Reduced-order Model Embedding for Cassie",
    authors: "Yu-Ming Chen and Michael Posa",
    abstract: "https://drive.google.com/file/d/19QK1xOWhvAeXeIg_GNBzAsoNPHvzDh9B/view",
    clip: "https://drive.google.com/file/d/1riRGMsgaPkiFL2Vmb5DAkkjF9UtLcw8v/view"
  },
  {
    title: "Is a minimalistic MPC based on centroidal dynamics enough to race complex vehicles?",
    authors: "Lorenzo Bartali, Michael Mugnai, Francesco Bucchi, Marco Gabiccini",
    abstract: "https://drive.google.com/file/d/1HDqIqMmmisjB7jXTqMHZfwR6_6s73bS1/view",
    clip: "https://drive.google.com/file/d/1Zrx_kBB_5N2ufkduca0e5fWd9pTGQaAd/view"
  },
  {
    title: "Improving Tail Compatibility Through Sequential Distributed Model Predictive Control",
    authors: "Yanhao Yang, Joseph Norby, Justin K. Yim, and Aaron M. Johnson",
    abstract: "https://drive.google.com/file/d/1znLvlbNYpMr8u6HqINxlvMcQ4xHFdA_t/view",
    clip: "https://drive.google.com/file/d/1ahcC2bhh52-l1f68dBHME3_3NNnQ6scx/view"
  },
  {
    title: "Invariant Model Predictive Control of Quadrotor Vehicles",
    authors: "James Richard Forbes, Faraaz Ahmed, Mitchell Cohen",
    abstract: "https://drive.google.com/file/d/1ZFp9v0gsRLWvZ5qmUCuHx2YU6czRiMRl/view",
    clip: "https://drive.google.com/file/d/1lb9mU8VIAtEhl_Gz8vQNJ8ad_TSNHaHK/view"
  },
  {
    title: "Linearized Contact-Implicit Model-Predictive Control",
    authors: "Simon Le Cleac'H and Taylor Howell",
    clip: "https://drive.google.com/file/d/1MsuskvFzzYoP8A6H07IpO7zdHd4YjZ4C/view"
  }
]