import React, { useState, useEffect } from "react";
import "./css/Navbar.scss";

function Navbar(props) {
  const [active, setActive] = useState(false);
  const [solidNavbar, setSolidNavbar] = useState(false);

  const handleScroll = () => {
    window.scrollY > 1 ? setSolidNavbar(true) : setSolidNavbar(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleClass = () => {
    setActive(!active);
  };

  const executeScroll = (selection = "") => {
    props.executeScroll(selection);
  };

  return (
    <>
      <div className={solidNavbar ? "solid-nav" : "clear-nav"}>
        <nav>
          <div onClick={() => executeScroll()} className="logo">
            MPC WORKSHOP |{" "}
            <span>
              <a
                href="https://roboticsconference.org"
                target="_blank"
                rel="noreferrer"
              >
                RSS 2021
              </a>
            </span>
          </div>
          <div
            onClick={() => executeScroll()}
            className={props.activeTab() === 1 ? "nav_link active" : "nav_link"}
          >
            Overview
          </div>
          <div
            onClick={() => executeScroll("program")}
            className={props.activeTab() === 2 ? "nav_link active" : "nav_link"}
          >
            Program
          </div>
          <div
            onClick={() => executeScroll("keynote")}
            className={props.activeTab() === 3 ? "nav_link active" : "nav_link"}
          >
            Keynotes
          </div>
          <div
            onClick={() => executeScroll("talks")}
            className={props.activeTab() === 4 ? "nav_link active" : "nav_link"}
          >
            Talks
          </div>
          <div
            onClick={() => executeScroll("people")}
            className={props.activeTab() === 5 ? "nav_link active" : "nav_link"}
          >
            People
          </div>
          <div
            onClick={() => executeScroll("solvers")}
            className={props.activeTab() === 6 ? "nav_link active" : "nav_link"}
          >
            Solvers
          </div>
          <div
            className={active ? "container change" : "container"}
            onClick={toggleClass}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </nav>
        {active && (
          <div active="true" className="hamburgerDropdown">
            <div
              onClick={() => {
                executeScroll();
                toggleClass();
              }}
            >
              Overview
            </div>
            <div
              onClick={() => {
                executeScroll("program");
                toggleClass();
              }}
            >
              Program
            </div>
            <div
              onClick={() => {
                executeScroll("keynote");
                toggleClass();
              }}
            >
              Keynotes
            </div>
            <div
              onClick={() => {
                executeScroll("talks");
                toggleClass();
              }}
            >
              Talks
            </div>
            <div
              onClick={() => {
                executeScroll("people");
                toggleClass();
              }}
            >
              People
            </div>
            <div
              onClick={() => {
                executeScroll("solvers");
                toggleClass();
              }}
            >
              Solvers
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Navbar;
