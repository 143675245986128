import React from "react";
import "./css/Program.scss";
import "../App.scss";

function Program(props) {
  // const [activeBlock, setActiveBlock] = useState("Block 1");

  const executeScroll = (selection = "") => {
    props.executeScroll(selection);
  };

  return (
    <div className="text">
      <div className="date_location">
        <h2 className="program_header">Synchronous Event - Tuesday, July 13</h2>
      </div>
      <br />
      <table>
        <tbody>
          <>
            <tr>
              <td className="time">10:00 PDT / 13:00 EDT / 19:00 CET</td>
              <td className="program_description">
                <h4 className="program_details">
                  <span className="visible--only-mobile">
                    10:00 PDT / 13:00 EDT / 19:00 CET:{" "}
                  </span>
                  <span
                    className="solver_link"
                    onClick={() => executeScroll("solvers")}
                  >
                    Croccodyl
                  </span>
                </h4>
                <p className="visible--only-mobile">
                  <a
                    href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Join Zoom
                  </a>
                </p>
              </td>
              <td className="link_cell">
                {" "}
                <a
                  href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                  rel="noreferrer"
                  target="_blank"
                >
                  Join Zoom
                </a>
              </td>
            </tr>
            <tr>
              <td className="time">10:45 PDT / 13:45 EDT / 19:45 CET</td>
              <td className="program_description">
                <h4 className="program_details">
                  <span className="visible--only-mobile">
                    10:45 PDT / 13:45 EDT / 19:45 CET:{" "}
                  </span>
                  <span
                    className="solver_link"
                    onClick={() => executeScroll("solvers")}
                  >
                    OCS2
                  </span>
                </h4>
                <p className="visible--only-mobile">
                  <a
                    href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Join Zoom
                  </a>
                </p>
              </td>
              <td className="link_cell">
                {" "}
                <a
                  href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                  rel="noreferrer"
                  target="_blank"
                >
                  Join Zoom
                </a>
              </td>
            </tr>
            <tr>
              <td className="time">11:30 PDT / 14:30 EDT / 20:30 CET</td>
              <td className="program_description">
                <h4 className="program_details">
                  <span className="visible--only-mobile">
                    11:30 PDT / 14:30 EDT / 20:30 CET:{" "}
                  </span>
                  <span
                    className="solver_link"
                    onClick={() => executeScroll("solvers")}
                  >
                    Altro
                  </span>
                </h4>
                <p className="visible--only-mobile">
                  <a
                    href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Join Zoom
                  </a>
                </p>
              </td>
              <td className="link_cell">
                {" "}
                <a
                  href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                  rel="noreferrer"
                  target="_blank"
                >
                  Join Zoom
                </a>
              </td>
            </tr>
            <tr>
              <td className="time">12:15 PDT / 15:15 EDT / 21:15 CET</td>
              <td className="program_description">
                <h4 className="program_details">
                  <span>
                    <span className="visible--only-mobile">
                      12:15 PDT / 15:15 EDT / 21:25 CET:{" "}
                    </span>
                    <span
                      className="solver_link"
                      onClick={() => executeScroll("talks")}
                    >
                      Poster Session for Lightning Talks
                    </span>
                  </span>
                </h4>
                <p>
                  We will hold an asynchronous poster session where workshop
                  attendees will have the opportunity to meet with the authors
                  of the submitted abstracts (
                  <a href="https://drive.google.com/drive/folders/14v5iniJo8xTqpQiq4IdbbwSNTJu9hZmi">
                    link here
                  </a>
                  ). Each abstract is paired with a quick 1-minute teaser.
                  Workshop attendees are encouraged to watch these prior to
                  joining the poster session. The link for this event will be
                  posted a few days before the event.
                </p>
                <p className="visible--only-mobile">
                  <a
                    href="https://www.wonder.me/r?id=49a37bb0-aa46-4677-8451-959caaa8c782"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Join Wonder.Me
                  </a>
                </p>
              </td>
              <td className="link_cell">
                {" "}
                <a
                  href="https://www.wonder.me/r?id=49a37bb0-aa46-4677-8451-959caaa8c782"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join Wonder.Me
                </a>
              </td>
            </tr>
            <tr>
              <td className="time">13:00 PDT / 16:00 EDT / 22:00 CET</td>
              <td className="program_description">
                <h4 className="program_details">
                  <span>
                    <span className="visible--only-mobile">
                      13:00 PDT / 16:00 EDT / 22:00 CET:{" "}
                    </span>
                    Panel Talks
                  </span>
                </h4>
                <p className="visible--only-mobile">
                  <a
                    href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Join Zoom
                  </a>
                </p>
              </td>
              <td className="link_cell">
                {" "}
                <a
                  href="https://cmu.zoom.us/j/94088073200?pwd=T1VoN21MaW9NZzlOM2xHMDBEVGxHQT09"
                  rel="noreferrer"
                  target="_blank"
                >
                  Join Zoom
                </a>
              </td>
            </tr>
            <tr>
              <td className="time">13:45 PDT / 16:45 EDT / 22:45 CET</td>
              <td className="program_description">
                <h4 className="program_details">
                  <span>
                    <span className="visible--only-mobile">
                      13:45 PDT / 16:45 EDT / 22:45 CET:{" "}
                    </span>
                    Open “office hours” for ALTRO and OCS2
                  </span>
                </h4>
                <p className="visible--only-mobile">
                  <a
                    href="https://www.wonder.me/r?id=49a37bb0-aa46-4677-8451-959caaa8c782"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Join Wonder.Me
                  </a>
                </p>
              </td>
              <td className="link_cell">
                {" "}
                <a
                  href="https://www.wonder.me/r?id=49a37bb0-aa46-4677-8451-959caaa8c782"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join Wonder.Me
                </a>
              </td>
            </tr>
          </>
        </tbody>
      </table>
    </div>
  );
}

export default Program;
