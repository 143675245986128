import React from "react";
import "./css/Keynote.scss";
import { speakers } from "../assets/data";

function Keynote() {
  return (
    <div className="text">
      <h2 className="keynote_header">Keynote Speakers</h2>
      <p>
        The keynote addresses will be delivered asynchronously as a
        seminar-style series in the weeks leading up to the day of the
        conference. Each session will consist of a 40-minute keynote address
        followed by a Q&A. The keynote speakers will be among the panelists
        selected for the closing discussion on the day of the workshop. Workshop
        participants will have an opportunity to submit questions via this
        website (link to be inserted shortly) that they wish to discuss with
        panelists on Tuesday, July 13.
      </p>
      {speakers.map((speaker, index) => {
        return (
            <div className="keynote" key={index}>
              <div className="keynote_description">
                <img
                  className="profile_image"
                  alt="ProfileImage"
                  src={speaker.image}
                />
                <h4>
                    <a className="keynote_link" href={speaker.externalLink} target="_blank" rel="noreferrer">{speaker.name}</a>
                </h4>
                <p>
                  {speaker.title}
                  {speaker.title && <br/>}
                  {speaker.date}  
                  <br/>
                  <a href={speaker.video} target="_blank" rel="noreferrer">
                      {speaker.label}
                  </a>
                </p>
                <p>{speaker.description}</p>
              </div>
            </div>
        );
      })}
    </div>
  );
}

export default Keynote;
