import React from "react";
import "../App.scss";
import "./css/Program.scss";
import { lightningTalks }  from "../assets/data";

function Talks() {
  return (
    <div className="text">
        <h2>Lightning Talks</h2>
        {lightningTalks.map((talk, index) => {
            return (
                <div className="lightning_talk" key={index}>
                    <p className="talk_title">{talk.title}</p>
                    <p>{talk.authors}</p>
                    <p>
                    <a href={talk.abstract} target="_blank" rel="noreferrer">Abstract</a>
                    <br/>
                    { talk.clip && <a href={talk.clip} target="_blank" rel="noreferrer">Video Clip</a> }
                    </p>
                </div>
                )
            })
        }

    </div>
  );
}

export default Talks;