import React from "react";
import "./css/Footer.scss";

function Footer() {
  return (
    <footer>
      <p>Tuesday July 13, 2021 1:00PM - 5:30PM EDT</p>
      <p>&copy; 2021 Alyssa Jackson. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
